import { NgModule } from '@angular/core';
import { CareerDetailsComponent } from './career-details/career-details.component';
import { ContactDetailsComponent } from './contact-details/contact-details.component';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    DashboardComponent,
    CareerDetailsComponent,
    ContactDetailsComponent
  ],
  imports: [
    DashboardRoutingModule,
    RouterModule
  ]
})
export class DashboardModule { }
