import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ICareer, IContact, IEmail, IQuote } from '../interfaces/common';
import { Meta } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  public selectedPage = new BehaviorSubject<string>('home');
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  baseURL = 'https://api.opusinfotech.co.in/';

  constructor(private http: HttpClient, private meta: Meta) {
  }

  // Send email
  sendEmail(emailData: IEmail): Observable<any> {
    return this.http.post<any>(this.baseURL + 'email/send', emailData, this.httpOptions);
  }

  // add/update SEO related details
  updateMetaTags(keywords: string, description: string) {
    this.meta.updateTag({ name: 'keywords', content: keywords });
    this.meta.updateTag({ name: 'description', content: description });
  }

  // add Career details
  postCareer(career: ICareer): Observable<any> {
    const body = JSON.stringify(career);
    return this.http.post(this.baseURL + 'careers', body, this.httpOptions)
  }

  // add Contact details
  postContact(contact: IContact): Observable<any> {
    const body = JSON.stringify(contact);
    return this.http.post(this.baseURL + 'contacts', body, this.httpOptions)
  }

  // Upload files to server
  uploadFile(formData: FormData) {
    return this.http.post(this.baseURL + 'files/upload', formData)
  }

  // add Free quote details
  postQuote(quote: IQuote): Observable<any> {
    const body = JSON.stringify(quote);
    return this.http.post(this.baseURL + 'quote', body, this.httpOptions)
  }

  setValue(value: string) {
    this.selectedPage.next(value);
  }

  getValue() {
    return this.selectedPage.asObservable();
  }
}
