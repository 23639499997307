import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { CareersComponent } from './careers/careers.component';
import { ContactComponent } from './contact/contact.component';
import { FeatureComponent } from './feature/feature.component';
import { HomeComponent } from './home/home.component';
import { PagesComponent } from './pages.component';
import { PlansComponent } from './plans/plans.component';
import { QuoteComponent } from './quote/quote.component';
import { ServiceComponent } from './service/service.component';
import { ServiceDetailComponent } from './service/service-detail/service-detail.component';


const routes: Routes = [
  {
    path: '', component: PagesComponent, children: [
      { path: 'home', component: HomeComponent },
      { path: 'about', component: AboutComponent },
      { path: 'careers', component: CareersComponent },
      { path: 'services', component: ServiceComponent },
      { path: 'service/:pageLink', component: ServiceDetailComponent },
      { path: 'contact', component: ContactComponent },
      { path: 'feature', component: FeatureComponent },
      // { path: 'plans', component: PlansComponent },
      { path: 'quote', component: QuoteComponent },
      // { path: 'team', component: TeamComponent },
      // { path: 'testimonial', component: TestimonialComponent },
      // otherwise redirect to Home
      { path: '', redirectTo: '/' + 'home', pathMatch: 'full' },
    ]
  }
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
