import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CareerDetailsComponent } from './career-details/career-details.component';
import { ContactDetailsComponent } from './contact-details/contact-details.component';
import { DashboardComponent } from './dashboard.component';

const routes: Routes = [
  {
    path: '', component: DashboardComponent, children: [
      { path: 'career-details', component: CareerDetailsComponent },
      { path: 'contact-details', component: ContactDetailsComponent }
    ]
  }
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
