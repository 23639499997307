<!-- Quote Start -->
<div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
    <div class="container py-5">
        <div class="row g-5">
            <div class="col-lg-7">
                <div class="section-title position-relative pb-3 mb-5">
                    <h5 class="fw-bold text-primary text-uppercase">Explore Exciting Opportunities &#64; OpusInfotech
                        Infotech</h5>
                    <h1 class="mb-0">Unlock Your Potential and Shape Your Future with Us!</h1>
                </div>
                <div class="row gx-3">
                    <div class="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                        <h5 class="mb-4"><i class="fa fa-briefcase text-primary me-3"></i>Explore opportunities</h5>
                    </div>
                    <div class="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                        <h5 class="mb-4"><i class="fa fa-car text-primary me-3"></i>Get ready to start your journey</h5>
                    </div>
                </div>
                <p class="mb-4">Join our dynamic team at Opus Infotech and embark on a rewarding career journey.
                    We believe in fostering a culture of innovation, collaboration, and continuous learning. As a member
                    of our team, you'll have the opportunity to work on cutting-edge projects, collaborate with talented
                    professionals, and contribute to meaningful solutions that make a difference. Whether you're an
                    experienced professional or a fresh graduate looking to kickstart your career, we provide a
                    supportive environment where your skills are valued and nurtured. Discover new possibilities and
                    grow your career with us at Opus Infotech.</p>
                <p class="mb-4">At Opus Infotech, we prioritize personal and professional growth, offering
                    training programs, mentorship opportunities, and career development resources to help you excel in
                    your role. Our inclusive and diverse work environment promotes creativity, innovation, and a sense
                    of belonging. We value teamwork, integrity, and a passion for excellence in everything we do.
                    Whether you're interested in software development, project management, or sales and marketing, we
                    have diverse roles that cater to various skills and interests. Join us to be part of a vibrant
                    community where your contributions are recognized, and your career aspirations are supported every
                    step of the way. Explore the possibilities and build a fulfilling career with Opus Infotech.
                </p>
                <div class="d-flex align-items-center mt-2 wow zoomIn" data-wow-delay="0.6s">
                    <div class="bg-primary d-flex align-items-center justify-content-center rounded"
                        style="width: 60px; height: 60px;">
                        <i class="fa fa-phone-alt text-white"></i>
                    </div>
                    <div class="ps-4">
                        <h5 class="mb-2">Call to ask any question</h5>
                        <h4 class="text-primary mb-0"><a href="tel:+919975608443" target="_blank">+91-9975608443</a></h4>
                    </div>
                </div>
            </div>
            <div class="col-lg-5">
                <div class="bg-primary rounded h-100 d-flex align-items-center p-5 wow zoomIn" data-wow-delay="0.9s">
                    <form [formGroup]="careerForm" (ngSubmit)="submit()" novalidate>
                        <div class="row g-3">
                            <div class="col-12">
                                <label for="applyFor" class="text-white fw-bold mb-2">Apply For</label>
                                <select formControlName="applyFor" class="form-select bg-light border-0" required
                                    [class.is-invalid]="applyFor.invalid && (applyFor.dirty || applyFor.touched)"
                                    style="height: 55px;" (change)="applyForType($event)">
                                    <option *ngIf="true" selected>Select A Job Type</option>
                                    <option value="Full time position">Full time position</option>
                                    <option value="Internship">Internship</option>
                                </select>
                                <div *ngIf="applyFor.invalid && (applyFor.dirty || applyFor.touched)"
                                    class="invalid-feedback">
                                    <div *ngIf="applyFor.errors?.['required']">
                                        This field is required.
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-floating">
                                    <input type="text" class="form-control" formControlName="firstName"
                                        placeholder="First Name" required class="form-control form-control-sm"
                                        [class.is-invalid]="firstName.invalid && (firstName.dirty || firstName.touched)">
                                    <div *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)"
                                        class="invalid-feedback">
                                        <div *ngIf="firstName.errors?.['required']">
                                            This field is required.
                                        </div>
                                        <div *ngIf="firstName.errors?.['minlength']">
                                            This field must have at least 3 character.
                                        </div>
                                        <div *ngIf="firstName.errors?.['maxlength']">
                                            This field must have at most 30 characters.
                                        </div>
                                        <div *ngIf="firstName.errors?.['pattern']">
                                            Please enter alphabet characters only
                                        </div>
                                    </div>
                                    <label for="firstName">First Name</label>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-floating">
                                    <input type="text" class="form-control" formControlName="lastName"
                                        placeholder="Last Name" required class="form-control form-control-sm"
                                        [class.is-invalid]="lastName.invalid && (lastName.dirty || lastName.touched)">
                                    <div *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)"
                                        class="invalid-feedback">
                                        <div *ngIf="lastName.errors?.['required']">
                                            This field is required.
                                        </div>
                                        <div *ngIf="lastName.errors?.['minlength']">
                                            This field must have at least 3 character.
                                        </div>
                                        <div *ngIf="lastName.errors?.['maxlength']">
                                            This field must have at most 30 characters.
                                        </div>
                                        <div *ngIf="lastName.errors?.['pattern']">
                                            Please enter alphabet characters only
                                        </div>
                                    </div>
                                    <label for="lastName">Last Name</label>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-floating">
                                    <input type="email" class="form-control" formControlName="email" placeholder="Email"
                                        required class="form-control form-control-sm"
                                        [class.is-invalid]="email.invalid && (email.dirty || email.touched)">
                                    <div *ngIf="email.invalid && (email.dirty || email.touched)"
                                        class="invalid-feedback">
                                        <div *ngIf="email.errors?.['required']">
                                            This field is required.
                                        </div>
                                        <div *ngIf="email.errors?.['minlength']">
                                            This field must have at least 3 character.
                                        </div>
                                        <div *ngIf="email.errors?.['maxlength']">
                                            This field must have at most 50 characters.
                                        </div>
                                        <div *ngIf="email.errors?.['email']">Email must be a valid email address</div>
                                    </div>
                                    <label for="email">Email</label>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-floating">
                                    <input type="text" class="form-control" formControlName="phone" placeholder="Phone"
                                        required class="form-control form-control-sm"
                                        [class.is-invalid]="phone.invalid && (phone.dirty || phone.touched)">
                                    <div *ngIf="phone.invalid && (phone.dirty || phone.touched)"
                                        class="invalid-feedback">
                                        <div *ngIf="phone.errors?.['required']">
                                            This field is required.
                                        </div>
                                        <div *ngIf="phone.errors?.['minlength']">
                                            Phone number must have at least 10 digits.
                                        </div>
                                        <div *ngIf="phone.errors?.['maxlength']">
                                            Phone number must have at most 10 digits.
                                        </div>
                                        <div *ngIf="phone.errors?.['pattern']">
                                            Please enter numeric values only.
                                        </div>
                                    </div>
                                    <label for="phone">Phone</label>
                                </div>
                            </div>
                            <div class="col-12">
                                <select formControlName="job" class="form-select bg-light border-0"
                                    style="height: 55px;" required
                                    [class.is-invalid]="job.invalid && (job.dirty || job.touched)">
                                    <option selected>Select A Job position</option>
                                    <option *ngIf="!internship" value="Frontend Developer">Frontend Developer</option>
                                    <option *ngIf="!internship" value="Backend Developer">Backend Developer</option>
                                    <option *ngIf="!internship" value="Data Engineer">Data Engineer</option>
                                    <option *ngIf="!internship" value="Test Engineer">Test Engineer</option>
                                    <option *ngIf="!internship" value="DevOps">DevOps</option>
                                    <option *ngIf="internship" value="One month">One month</option>
                                    <option *ngIf="internship" value="Three month">Three month</option>
                                    <option *ngIf="internship" value="Six month">Six month</option>
                                </select>
                                <div *ngIf="job.invalid && (job.dirty || job.touched)" class="invalid-feedback">
                                    <div *ngIf="job.errors?.['required']">
                                        This field is required.
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6" *ngIf="internship">
                                <div class="form-floating">
                                    <input type="text" class="form-control" formControlName="collegeName"
                                        placeholder="College Name">
                                    <label for="lastName">College name</label>
                                </div>
                            </div>
                            <div class="col-md-6" *ngIf="internship">
                                <div class="form-floating">
                                    <input type="text" class="form-control" formControlName="department"
                                        placeholder="Department">
                                    <label for="lastName">Department</label>
                                </div>
                            </div>
                            <div class="col-12" *ngIf="internship">
                                <div class="form-floating">
                                    <textarea class="form-control" placeholder="About yourself"
                                        formControlName="aboutYourself" style="height: 150px"></textarea>
                                    <label for="message">About yourself</label>
                                </div>
                            </div>
                            <div class="col-12">
                                <label for="formFile" class="form-label text-white fw-bold">Upload Resume</label>
                                <input class="form-control" type="file" name="file" formControlName="fileName"
                                    (change)="onFileSelected($event)" accept=".pdf,.doc,.docx">
                            </div>
                            <div class="col-12">
                                <button type="submit" class="btn btn-dark w-100 py-3">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Quote End -->