<!-- Contact Start -->
<div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
    <div class="container py-5">
        <div class="section-title text-center position-relative pb-3 mb-5 mx-auto" style="max-width: 600px;">
            <h5 class="fw-bold text-primary text-uppercase">Contact Us</h5>
            <h1 class="mb-0">If You Have Any Query, Feel Free To Contact Us</h1>
        </div>
        <div class="row g-5 mb-5">
            <div class="col-lg-4">
                <div class="d-flex align-items-center wow fadeIn" data-wow-delay="0.1s">
                    <div class="bg-primary d-flex align-items-center justify-content-center rounded"
                        style="width: 60px; height: 60px;">
                        <i class="fa fa-phone-alt text-white"></i>
                    </div>
                    <div class="ps-4">
                        <h5 class="mb-2">Call to ask any question</h5>
                        <h4 class="text-primary mb-0"><a href="tel:+919975608443" target="_blank">+91-9975608443</a></h4>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="d-flex align-items-center wow fadeIn" data-wow-delay="0.4s">
                    <div class="bg-primary d-flex align-items-center justify-content-center rounded"
                        style="width: 60px; height: 60px;">
                        <i class="fa fa-envelope-open text-white"></i>
                    </div>
                    <div class="ps-4">
                        <h5 class="mb-2">Email to get free quote</h5>
                        <h4 class="text-primary mb-0"><a
                                href="mailto:info@opusinfotech.co.in?subject=OpusInfotech - Enquiry"
                                target="_blank">info&#64;opusinfotech.co.in</a></h4>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="d-flex align-items-center wow fadeIn" data-wow-delay="0.8s">
                    <div class="bg-primary d-flex align-items-center justify-content-center rounded"
                        style="min-width: 60px; height: 60px;">
                        <i class="fa fa-map-marker-alt text-white"></i>
                    </div>
                    <div class="ps-4">
                        <h5 class="mb-2">Visit our office</h5>
                        <h4 class="text-primary mb-0"><a
                                href="https://maps.app.goo.gl/h9bhixRsV7cci3xH7"
                                target="_blank">21A-985, Patil Nagar, Umarkhed, Yavatmal, Maharashtra, India-445206</a></h4>
                    </div>
                </div>
            </div>
        </div>
        <div class="row g-5">
            <div class="col-lg-6 wow slideInUp" data-wow-delay="0.3s">
                <div class="bg-primary rounded h-100 d-flex align-items-center p-5 wow zoomIn" data-wow-delay="0.9s">
                    <form [formGroup]="contactForm" (ngSubmit)="submit()" novalidate>
                        <div class="row g-3">
                            <div class="col-md-6">
                                <input type="text" formControlName="firstName"
                                    class="form-control border-0 bg-light px-4" placeholder="Your First Name"
                                    style="height: 55px;" required class="form-control form-control-sm"
                                    [class.is-invalid]="firstName.invalid && (firstName.dirty || firstName.touched)">
                                <div *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)"
                                    class="invalid-feedback">
                                    <div *ngIf="firstName.errors?.['required']">
                                        This field is required.
                                    </div>
                                    <div *ngIf="firstName.errors?.['minlength']">
                                        This field must have at least 1 character.
                                    </div>
                                    <div *ngIf="firstName.errors?.['maxlength']">
                                        This field must have at most 30 characters.
                                    </div>
                                    <div *ngIf="firstName.errors?.['pattern']">
                                        Please enter alphabet characters only
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <input type="text" formControlName="lastName"
                                    class="form-control border-0 bg-light px-4" placeholder="Your Last Name"
                                    style="height: 55px;" required class="form-control form-control-sm"
                                    [class.is-invalid]="lastName.invalid && (lastName.dirty || lastName.touched)">
                                <div *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)"
                                    class="invalid-feedback">
                                    <div *ngIf="lastName.errors?.['required']">
                                        This field is required.
                                    </div>
                                    <div *ngIf="lastName.errors?.['minlength']">
                                        This field must have at least 1 character.
                                    </div>
                                    <div *ngIf="lastName.errors?.['maxlength']">
                                        This field must have at most 30 characters.
                                    </div>
                                    <div *ngIf="lastName.errors?.['pattern']">
                                        Please enter alphabet characters only
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <input type="email" formControlName="email" class="form-control border-0 bg-light px-4"
                                    placeholder="Your Email" style="height: 55px;" required
                                    class="form-control form-control-sm"
                                    [class.is-invalid]="email.invalid && (email.dirty || email.touched)">
                                <div *ngIf="email.invalid && (email.dirty || email.touched)" class="invalid-feedback">
                                    <div *ngIf="email.errors?.['required']">
                                        This field is required.
                                    </div>
                                    <div *ngIf="email.errors?.['minlength']">
                                        This field must have at least 1 character.
                                    </div>
                                    <div *ngIf="email.errors?.['maxlength']">
                                        This field must have at most 50 characters.
                                    </div>
                                    <div *ngIf="email.errors?.['email']">Email must be a valid email address</div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <input type="text" class="form-control" formControlName="contact" placeholder="Phone"
                                    required class="form-control form-control-sm" style="height: 55px;"
                                    [class.is-invalid]="contact.invalid && (contact.dirty || contact.touched)">
                                <div *ngIf="contact.invalid && (contact.dirty || contact.touched)"
                                    class="invalid-feedback">
                                    <div *ngIf="contact.errors?.['required']">
                                        This field is required.
                                    </div>
                                    <div *ngIf="contact.errors?.['minlength']">
                                        Phone number must have at least 10 digits.
                                    </div>
                                    <div *ngIf="contact.errors?.['maxlength']">
                                        Phone number must have at most 10 digits.
                                    </div>
                                    <div *ngIf="contact.errors?.['pattern']">
                                        Please enter numeric values only.
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <input type="text" formControlName="company" class="form-control border-0 bg-light px-4"
                                    placeholder="Your Company" style="height: 55px;" required
                                    class="form-control form-control-sm"
                                    [class.is-invalid]="company.invalid && (company.dirty || company.touched)">
                                <div *ngIf="company.invalid && (company.dirty || company.touched)"
                                    class="invalid-feedback">
                                    <div *ngIf="company.errors?.['required']">
                                        This field is required.
                                    </div>
                                    <div *ngIf="company.errors?.['minlength']">
                                        This field must have at least 1 character.
                                    </div>
                                    <div *ngIf="company.errors?.['maxlength']">
                                        This field must have at most 60 characters.
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <textarea formControlName="message" class="form-control border-0 bg-light px-4 py-3"
                                    rows="4" placeholder="Message"></textarea>
                            </div>
                            <div class="col-12">
                                <button type="submit" class="btn btn-dark w-100 py-3" type="submit">Send
                                    Message</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-lg-6 wow slideInUp" data-wow-delay="0.6s">
                <iframe class="position-relative rounded w-100 h-100"
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d30067.282582679025!2d77.685978!3d19.609748!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd19375963be257%3A0x5973b1c0178717c1!2sOpus%20Infotech!5e0!3m2!1sen!2sin!4v1715162552426!5m2!1sen!2sin"
                    frameborder="0" style="min-height: 350px; border:0;" allowfullscreen="" aria-hidden="false"
                    tabindex="0"></iframe>
            </div>
        </div>
    </div>
</div>
<!-- Contact End -->