<!-- Quote Start -->
<div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
    <div class="container py-5">
        <div class="row g-5">
            <div class="col-lg-7">
                <div class="section-title position-relative pb-3 mb-5">
                    <h5 class="fw-bold text-primary text-uppercase">Request A Quote</h5>
                    <h1 class="mb-0">Need A Free Quote? Please Feel Free to Contact Us</h1>
                </div>
                <div class="row gx-3">
                    <div class="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                        <h5 class="mb-4"><i class="fa fa-reply text-primary me-3"></i>Reply within 24 hours</h5>
                    </div>
                    <div class="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                        <h5 class="mb-4"><i class="fa fa-phone-alt text-primary me-3"></i>24 hrs telephone support</h5>
                    </div>
                </div>
                <div class="mt-2 mb-5">
                    <p>Are you ready to turn your ideas into reality? Our expert team is here to help. Whether
                        you&apos;re looking for web design, development, or digital marketing services, we&apos;ve got
                        you covered. Get started by requesting a free quote tailored to your project requirements.</p>
                    <!-- <p><strong>Our Services Include:</strong></p>
                    <ul>
                        <li><strong>Web Design:</strong> Captivating designs that reflect your brand identity and engage
                            your audience.</li>
                        <li><strong>Web Development:</strong> Robust and functional websites built with the latest
                            technologies for optimal performance.</li>
                        <li><strong>Digital Marketing:</strong> Strategic campaigns to boost your online presence and
                            drive growth.</li>
                    </ul> -->
                    <p><strong>Why Choose Us?</strong></p>
                    <ul>
                        <li><strong>Experience:</strong> Years of industry expertise delivering successful projects.
                        </li>
                        <li><strong>Tailored Solutions:</strong> Customized solutions to meet your unique business
                            needs.</li>
                        <li><strong>Dedicated Support:</strong> Friendly and responsive customer support every step of
                            the way.</li>
                    </ul>
                    <p><strong>How It Works:</strong></p>
                    <ol>
                        <li><strong>Contact Us:</strong> Reach out via phone, email, or our online form.</li>
                        <li><strong>Consultation:</strong> Discuss your project requirements with our team.</li>
                        <li><strong>Get Your Quote:</strong> Receive a detailed quote outlining our services and
                            pricing.</li>
                    </ol>
                    <p><strong>Let&apos;s Get Started! </strong>Don&apos;t let your ideas remain just ideas. Contact us
                        today for a free consultation and quote. We&apos;re excited to work with you and bring your
                        vision to life!</p>
                </div>
                <div class="d-flex align-items-center mt-2 wow zoomIn" data-wow-delay="0.6s">
                    <div class="bg-primary d-flex align-items-center justify-content-center rounded"
                        style="width: 60px; height: 60px;">
                        <i class="fa fa-phone-alt text-white"></i>
                    </div>
                    <div class="ps-4">
                        <h5 class="mb-2">Call to ask any question</h5>
                        <h4 class="text-primary mb-0"><a href="tel:+919975608443" target="_blank">+91-9975608443</a>
                        </h4>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 h-100">
                <div class="bg-primary rounded h-100 d-flex align-items-center p-5 wow zoomIn" data-wow-delay="0.9s">
                    <form [formGroup]="quoteForm" (ngSubmit)="submit()" novalidate>
                        <div class="row g-3">
                            <div class="col-xl-12">
                                <input type="text" formControlName="fullName" class="form-control bg-light border-0"
                                    placeholder="Your Name" style="height: 55px;"
                                    [class.is-invalid]="fullName.invalid && (fullName.dirty || fullName.touched)">
                                <div *ngIf="fullName.invalid && (fullName.dirty || fullName.touched)"
                                    class="invalid-feedback">
                                    <div *ngIf="fullName.errors?.['required']">
                                        This field is required.
                                    </div>
                                    <div *ngIf="fullName.errors?.['minlength']">
                                        This field must have at least 1 character.
                                    </div>
                                    <div *ngIf="fullName.errors?.['maxlength']">
                                        This field must have at most 30 characters.
                                    </div>
                                    <div *ngIf="fullName.errors?.['pattern']">
                                        Please enter alphabet characters only
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <input type="email" formControlName="email" class="form-control bg-light border-0"
                                    placeholder="Your Email" style="height: 55px;"
                                    [class.is-invalid]="email.invalid && (email.dirty || email.touched)">
                                <div *ngIf="email.invalid && (email.dirty || email.touched)" class="invalid-feedback">
                                    <div *ngIf="email.errors?.['required']">
                                        This field is required.
                                    </div>
                                    <div *ngIf="email.errors?.['minlength']">
                                        This field must have at least 1 character.
                                    </div>
                                    <div *ngIf="email.errors?.['maxlength']">
                                        This field must have at most 50 characters.
                                    </div>
                                    <div *ngIf="email.errors?.['email']">Email must be a valid email address</div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <input type="text" class="form-control" formControlName="contact" placeholder="Phone"
                                    required class="form-control form-control-sm" style="height: 55px;"
                                    [class.is-invalid]="contact.invalid && (contact.dirty || contact.touched)">
                                <div *ngIf="contact.invalid && (contact.dirty || contact.touched)"
                                    class="invalid-feedback">
                                    <div *ngIf="contact.errors?.['required']">
                                        This field is required.
                                    </div>
                                    <div *ngIf="contact.errors?.['minlength']">
                                        Phone number must have at least 10 digits.
                                    </div>
                                    <div *ngIf="contact.errors?.['maxlength']">
                                        Phone number must have at most 10 digits.
                                    </div>
                                    <div *ngIf="contact.errors?.['pattern']">
                                        Please enter numeric values only.
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <select formControlName="service" class="form-select bg-light border-0"
                                    style="height: 55px;" required
                                    [class.is-invalid]="service.invalid && (service.dirty || service.touched)">
                                    <option selected>Select A Service</option>
                                    <option value="AI/Machine Learning">AI/Machine Learning Solutions</option>
                                    <option value="IT Security">IT Security Services</option>
                                    <option value="Maintenance and Support">Maintenance and Support Services</option>
                                    <option value="Quality Assurance (QA) and Testing">Quality Assurance (QA) and
                                        Testing</option>
                                    <option value="Enterprise Solutions">Enterprise Solutions</option>
                                    <option value="E-commerce Solutions">E-commerce Solutions</option>
                                    <option value="Cloud Solutions">Cloud Solutions</option>
                                    <option value="Database Solutions">Database Solutions</option>
                                    <option value="Mobile App Development">Mobile App Development</option>
                                    <option value="Web Development">Web Development Services</option>
                                    <option value="UI/UX Design">UI/UX Design Services</option>
                                    <option value="Software Consulting">Software Consulting</option>
                                    <option value="Custom Software Development">Custom Software Development</option>
                                    <option value="Digital Marketing">Digital Marketing Solutions</option>
                                </select>
                                <div *ngIf="service.invalid && (service.dirty || service.touched)"
                                    class="invalid-feedback">
                                    <div *ngIf="service.errors?.['required']">
                                        This field is required.
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <textarea formControlName="message" class="form-control bg-light border-0" rows="3"
                                    placeholder="Message"></textarea>
                            </div>
                            <div class="col-12">
                                <button class="btn btn-dark w-100 py-3" type="submit">Request A Quote</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Quote End -->