import { Component, OnInit } from '@angular/core';
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})
export class PagesComponent implements OnInit {
  title = 'OpusInfotech';
  public showCarousel = true;
  public selectedPage: any;
  isNavbarCollapsed: boolean = false;

  toggleNavbar() {
    this.isNavbarCollapsed = !this.isNavbarCollapsed;
  }

  constructor(private commonService: CommonService) { }

  updateValue(item: any) {
    if (this.isNavbarCollapsed) {
      this.isNavbarCollapsed = false;
    }
    this.commonService.setValue(item);
  }

  ngOnInit() {
    this.commonService.selectedPage.subscribe(res => {
      this.selectedPage = res;
      this.toggleMenu();
    });
  }

  toggleMenu() {
    if (this.selectedPage === 'home') {
      this.showCarousel = true;
    }
    else {
      this.showCarousel = false;
      switch (this.selectedPage) {
        case 'about':
          this.selectedPage = 'About Us';
          break;
        case 'careers':
          this.selectedPage = 'Careers';
          break;
        case 'services':
          this.selectedPage = 'Our Services';
          break;
        case 'contact':
          this.selectedPage = 'Contact Us';
          break;
        case 'team':
          this.selectedPage = 'Meet The Team';
          break;
        case 'feature':
          this.selectedPage = 'Our Feature';
          break;
        // case 'testimonial':
        //   this.selectedPage = 'Testimonial';
        //   break;
        case 'quote':
          this.selectedPage = 'Free Quote';
          break;
        // case 'plans':
        //   this.selectedPage = 'Pricing Plans';
        //   break;
        default:
          this.selectedPage = '';
          break;
      }
    }
  }
}
