<div class="mb-md-5 mt-md-4 pb-5">
  
    <h2 class="fw-bold mb-2 text-uppercase">Login</h2>
    <p class="text-white-50 mb-5">Please enter your login and password!</p>

    <div class="form-outline form-white mb-4">
      <input type="email" id="typeEmailX" class="form-control form-control-lg" />
      <label class="form-label" for="typeEmailX">Email</label>
    </div>

    <div class="form-outline form-white mb-4">
      <input type="password" id="typePasswordX" class="form-control form-control-lg" />
      <label class="form-label" for="typePasswordX">Password</label>
    </div>

    <p class="small mb-5 pb-lg-2"><a class="text-white-50" href="#!">Forgot password?</a></p>

    <button class="btn btn-outline-light btn-lg px-5" type="submit">Login</button>

    <div class="d-flex justify-content-center text-center mt-4 pt-1">
      <a href="#!" class="text-white"><i class="fab fa-facebook-f fa-lg"></i></a>
      <a href="#!" class="text-white"><i class="fab fa-twitter fa-lg mx-4 px-2"></i></a>
      <a href="#!" class="text-white"><i class="fab fa-google fa-lg"></i></a>
    </div>

  </div>