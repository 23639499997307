import { Component, OnInit } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { IServices, listOfServices } from 'src/app/interfaces/common';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-service-detail',
  templateUrl: './service-detail.component.html',
  styleUrls: ['./service-detail.component.scss']
})
export class ServiceDetailComponent implements OnInit {
  services: IServices[] = listOfServices;
  selectedServiceDetail: IServices | undefined = undefined; // Initialize as undefined

  constructor(private route: ActivatedRoute,private router: Router, private commonService: CommonService, private sanitizer: DomSanitizer, private titleService: Title) { }

  ngOnInit(): void {
        this.commonService.setValue('services');
    this.titleService.setTitle(''); // Set your title here
    const keywords = ''; // Define your keywords
    const description = ''; // Define your description
    this.commonService.updateMetaTags(keywords, description); // Update meta tags

    const pageLink = this.route.snapshot.paramMap.get('pageLink');
    if (pageLink) {
      this.loadServiceDetailById(pageLink);
    }
  }



  loadServiceDetailById(pageLink: string): void {
    const foundService = this.services.find(service => service.pageLink == pageLink);
    if (foundService) {
      this.selectedServiceDetail = {
        id: foundService.id,
        icon: foundService.icon,
        name: foundService.name,
        description: foundService.description,
        pageLink: foundService.pageLink,
        pageDescription:foundService.pageDescription,
        featuredImage:foundService.featuredImage
      };
    } else {
      alert('Service not found');
      this.router.navigateByUrl('services');
    }
  }

  updateValue(item: any) {
    this.commonService.setValue(item);
  }
}
