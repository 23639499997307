import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.scss']
})
export class PlansComponent  implements OnInit {
  constructor(private commonService: CommonService) {
  }

  ngOnInit() {
    this.commonService.setValue('');
  }

}
