export interface Common {
}

export interface ICareer {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  applyFor: string;
  job: string;
  collegeName: string;
  department: string;
  aboutYourself: string;
  fileName: string;
  message: string;
  orgId: string;
}

export interface IContact {
  firstName: string;
  lastName: string;
  email: string;
  contact: string;
  company: string;
  message: string;
  orgId: string;
}

export interface IQuote {
  fullName: string;
  email: string;
  contact: string;
  service: string;
  message: string;
  orgId: string;
}

export interface IEmail { subject: string; body: string; recipient: string; fileName: string, orgId: number };

export interface IServices {
  id: number,
  icon: string,
  name: string,
  description: string,
  pageLink: string,
  pageDescription: string,
  featuredImage: string
}

export const listOfHomeServices: IServices[] = [
  {
    id: 1,
    icon: 'fas fa-brain',
    name: 'AI/Machine Learning',
    description: 'Transform your business with AI and machine learning solutions for enhanced automation and intelligent decision-making.',
    pageLink: 'ai-machine-learning',
    pageDescription: '',
    featuredImage: ''
  },
  {
    id: 2,
    icon: 'fa fa-shield-alt',
    name: 'IT Security Services',
    description: 'Protecting your digital assets with robust IT security services and proactive threat management.',
    pageLink: 'it-security-services',
    pageDescription: '',
    featuredImage: ''
  },
  {
    id: 3,
    icon: 'fa fa-search',
    name: 'Maintenance and Support',
    description: 'Providing reliable maintenance and support services to ensure seamless operation and ongoing optimization of your systems.',
    pageLink: 'maintenance-and-support',
    pageDescription: '',
    featuredImage: ''
  },
  {
    id: 13,
    icon: 'fa fa-code',
    name: 'Custom Software Development',
    description: 'Tailored software solutions designed to meet your unique business needs and goals.',
    pageLink: 'custom-software-development',
    pageDescription: '',
    featuredImage: ''
  },
  {
    id: 14,
    icon: 'fa fa-bullhorn',
    name: 'Digital Marketing',
    description: 'Driving online visibility and engagement through strategic digital marketing solutions.',
    pageLink: 'digital-marketing',
    pageDescription: '',
    featuredImage: ''
  }
];

export const listOfServices: IServices[] = [
  {
    id: 1,
    icon: 'fas fa-brain',
    name: 'AI/Machine Learning',
    description: 'Transform your business with AI and machine learning solutions for enhanced automation and intelligent decision-making.',
    pageLink: 'ai-machine-learning',
    pageDescription: '<p><span>Welcome to our AI/Machine Learning services, where cutting-edge technology meets real-world solutions. In today&apos;s rapidly evolving digital landscape, businesses need more than just data; they need actionable insights and predictive capabilities to stay competitive. Our AI and machine learning solutions are designed to unlock the full potential of your data, empowering you to make informed decisions, automate processes, and deliver personalized experiences to your customers.</span></p><p><span>At Opus Infotech, we offer a range of AI and machine learning services tailored to your business needs. Whether you&apos;re looking to build custom AI models, harness predictive analytics, leverage natural language processing (NLP) for intelligent interactions, or dive into the realm of computer vision for image recognition and analysis, we have the expertise and tools to bring your ideas to life.</span></p><p><span>Our approach is rooted in understanding your unique challenges and goals. We work closely with your team to develop customized solutions that align with your business objectives. From initial consultation and solution design to seamless integration and ongoing support, we are committed to helping you leverage AI and machine learning technologies effectively.</span></p><p><span>By partnering with us, you gain access to scalable, accurate, and secure AI solutions that drive efficiency, uncover valuable insights, and propel your business forward. Join leading organizations across industries who have embraced AI to transform operations, enhance customer experiences, and gain a competitive edge.</span></p><p><span>Explore our AI/Machine Learning services today and discover the power of intelligent technology for your business.</span></p>',
    featuredImage: 'ai-machine-learning.jpeg'
  },
  {
    id: 2,
    icon: 'fa fa-shield-alt',
    name: 'IT Security Services',
    description: 'Protecting your digital assets with robust IT security services and proactive threat management.',
    pageLink: 'it-security-services',
    pageDescription: `<p>Welcome to our comprehensive IT Security Services designed to safeguard your digital assets and protect your business from evolving cyber threats. In today&apos;s interconnected world, securing your IT infrastructure and sensitive data is paramount. At Opus Infotech, we offer a range of robust IT security solutions tailored to your specific needs, ensuring peace of mind and compliance with industry standards.</p>
    <p>Our IT Security Services encompass a holistic approach to safeguarding your digital environment. From proactive threat detection and prevention to incident response and recovery, we have you covered at every step. Our team of cybersecurity experts stays abreast of the latest threats and trends, leveraging advanced technologies and best practices to fortify your defenses.</p>
    <p>Key components of our IT Security Services include:</p>
    <ul>
        <li>
            <p><strong>Network Security:</strong> Implementing firewalls, intrusion detection systems, and secure VPNs to protect your network from unauthorized access and cyberattacks.</p>
        </li>
        <li>
            <p><strong>Data Encryption:</strong> Securing sensitive data through encryption algorithms and robust encryption key management practices, ensuring confidentiality and integrity.</p>
        </li>
        <li>
            <p><strong>Endpoint Protection:</strong> Deploying endpoint security solutions such as antivirus software, endpoint detection and response (EDR), and mobile device management (MDM) to secure endpoints across your organization.</p>
        </li>
        <li>
            <p><strong>Security Audits and Compliance:</strong> Conducting thorough security audits, vulnerability assessments, and compliance checks to identify gaps and ensure adherence to regulatory requirements such as GDPR, HIPAA, PCI DSS, and more.</p>
        </li>
        <li>
            <p><strong>Incident Response:</strong> Developing and implementing incident response plans, conducting forensics investigations, and providing timely response and recovery measures in the event of a security breach.</p>
        </li>
    </ul>
    <p>Our approach emphasizes collaboration and transparency, working closely with your team to understand your unique security challenges and business objectives. Whether you operate in healthcare, finance, government, or any other sector, our tailored IT Security Services are designed to mitigate risks, protect critical assets, and uphold the trust of your customers and stakeholders.</p>
    <p>Don&apos;t let cyber threats compromise your business. Partner with us for robust IT security solutions that deliver resilience, compliance, and peace of mind.</p>`,
    featuredImage: 'it-security-services.jpeg'
  },
  {
    id: 3,
    icon: 'fa fa-search',
    name: 'Maintenance and Support',
    description: 'Providing reliable maintenance and support services to ensure seamless operation and ongoing optimization of your systems.',
    pageLink: 'maintenance-and-support',
    pageDescription: `<p>Welcome to our Maintenance and Support services, where reliability meets peace of mind. At Opus Infotech, we understand the importance of keeping your systems, software, and infrastructure running smoothly to ensure uninterrupted business operations. Our comprehensive maintenance and support services are designed to proactively monitor, troubleshoot, and optimize your technology investments.</p>
    <h3>Our Maintenance and Support Offerings:</h3>
    <ul>
        <li>
            <p><strong>24/7 Monitoring:</strong> Continuous monitoring of systems and networks to detect and address issues before they impact performance or availability.</p>
        </li>
        <li>
            <p><strong>Regular Updates and Patches:</strong> Timely application of software updates, security patches, and firmware upgrades to protect against vulnerabilities and ensure optimal performance.</p>
        </li>
        <li>
            <p><strong>Technical Support:</strong> Dedicated technical experts available round-the-clock to provide assistance, resolve issues, and offer guidance on system maintenance and optimization.</p>
        </li>
        <li>
            <p><strong>Performance Optimization:</strong> Fine-tuning system configurations, optimizing resources, and implementing best practices to maximize efficiency and reliability.</p>
        </li>
        <li>
            <p><strong>Backup and Disaster Recovery:</strong> Implementing robust backup solutions and disaster recovery plans to safeguard data and ensure business continuity in case of unexpected events.</p>
        </li>
        <li>
            <p><strong>Security Enhancements:</strong> Implementing and updating security protocols, access controls, and threat detection mechanisms to mitigate risks and protect against cyber threats.</p>
        </li>
    </ul>
    <h3>Why Choose Us for Maintenance and Support?</h3>
    <ul>
        <li>
            <p><strong>Expertise:</strong> Our team comprises experienced professionals with expertise across various technologies and industries, ensuring tailored solutions for your specific needs.</p>
        </li>
        <li>
            <p><strong>Proactive Approach:</strong> We take a proactive approach to maintenance, identifying potential issues early and implementing preventive measures to minimize downtime and disruptions.</p>
        </li>
        <li>
            <p><strong>Scalability:</strong> Our services are scalable to accommodate your evolving needs, whether you are a small business or a large enterprise.</p>
        </li>
        <li>
            <p><strong>Cost-Effectiveness:</strong> By outsourcing maintenance and support to us, you can reduce operational costs associated with hiring and training in-house IT teams while ensuring high-quality service delivery.</p>
        </li>
    </ul>
    <h3>Get Started with Reliable Maintenance and Support</h3>
    <p>Partner with us to benefit from reliable maintenance, proactive support, and optimized performance for your IT systems and infrastructure. Focus on your core business activities while we take care of your technology backbone.</p>
    <p>Contact us today to discuss your maintenance and support requirements and discover how we can support your success.</p>`,
    featuredImage: 'maintenance-and-support.jpeg'
  },
  {
    id: 4,
    icon: 'fas fa-check-circle',
    name: 'Quality Assurance (QA) and Testing',
    description: 'Ensuring top-notch quality and performance through rigorous QA and testing processes.',
    pageLink: 'qa-and-testing',
    pageDescription: `<p>Welcome to our Quality Assurance (QA) and Testing services, where precision meets performance. At Opus Infotech, we understand the critical role of quality assurance and testing in delivering reliable, user-friendly software solutions. Our comprehensive QA and testing services are designed to identify issues early, ensure optimal functionality, and enhance the overall user experience.</p>
    <h3>Our QA and Testing Offerings:</h3>
    <ul>
        <li>
            <p><strong>Test Strategy and Planning:</strong> Developing customized test strategies, plans, and methodologies tailored to your project requirements and goals.</p>
        </li>
        <li>
            <p><strong>Functional Testing:</strong> Ensuring that software functions as intended by testing individual features, modules, and integrations to validate business logic and user workflows.</p>
        </li>
        <li>
            <p><strong>Regression Testing:</strong> Verifying that recent code changes or updates do not negatively impact existing functionalities, ensuring backward compatibility and stability.</p>
        </li>
        <li>
            <p><strong>Performance Testing:</strong> Evaluating system performance under various load conditions to assess scalability, responsiveness, and reliability.</p>
        </li>
        <li>
            <p><strong>Security Testing:</strong> Identifying vulnerabilities, loopholes, and security risks through penetration testing, code reviews, and security audits to fortify your software against cyber threats.</p>
        </li>
        <li>
            <p><strong>Usability and User Experience (UX) Testing:</strong> Assessing software usability, accessibility, and overall user experience to ensure intuitive interfaces and customer satisfaction.</p>
        </li>
    </ul>
    <h3>Why Choose Us for QA and Testing?</h3>
    <ul>
        <li>
            <p><strong>Expertise:</strong> Our QA and testing team comprises skilled professionals with expertise in industry-standard testing tools, methodologies, and best practices.</p>
        </li>
        <li>
            <p><strong>Comprehensive Approach:</strong> We adopt a comprehensive testing approach covering functional, performance, security, and usability aspects to deliver high-quality, reliable software.</p>
        </li>
        <li>
            <p><strong>Continuous Improvement:</strong> We continuously refine our testing processes, adopt automation where applicable, and stay updated with emerging trends to deliver efficient and effective testing solutions.</p>
        </li>
        <li>
            <p><strong>Collaborative Partnership:</strong> We work closely with your development team or project stakeholders to align testing efforts with project milestones, timelines, and quality objectives.</p>
        </li>
    </ul>
    <h3>Elevate Software Quality with Our QA and Testing Services</h3>
    <p>Partner with us to ensure robust, error-free software solutions that meet user expectations, regulatory standards, and business goals. From initial test planning to execution and reporting, we are committed to delivering excellence in QA and testing.</p>
    <p>Contact us today to discuss your QA and testing requirements and discover how we can elevate the quality and reliability of your software products.</p>`,
    featuredImage: 'qa-and-testing.jpeg'
  },
  {
    id: 5,
    icon: 'fa fa-building',
    name: 'Enterprise Solutions',
    description: 'Delivering comprehensive and scalable enterprise solutions to streamline operations and drive business growth.',
    pageLink: 'enterprise-solutions',
    pageDescription: `<p>Welcome to our Enterprise Solutions services, where innovation meets scalability. At Opus Infotech, we specialize in delivering customized enterprise solutions that empower organizations to streamline operations, enhance productivity, and drive growth. Whether you need robust ERP systems, scalable CRM solutions, workflow automation, or data analytics platforms, our experienced team is equipped to meet your enterprise-level needs.</p>
    <h3>Our Enterprise Solutions Offerings:</h3>
    <ul>
    <li>
    <p><strong>Enterprise Resource Planning (ERP):</strong> Designing, implementing, and customizing ERP systems to integrate core business processes such as finance, HR, inventory management, and supply chain management for improved efficiency and decision-making.</p>
    </li>
    <li>
    <p><strong>Customer Relationship Management (CRM):</strong> Developing CRM solutions tailored to your customer engagement strategies, sales pipelines, marketing campaigns, and customer service interactions to drive customer loyalty and revenue growth.</p>
    </li>
    <li>
    <p><strong>Workflow Automation:</strong> Implementing workflow automation solutions using BPM (Business Process Management) tools, AI-driven workflows, and robotic process automation (RPA) to streamline repetitive tasks, reduce errors, and optimize resource utilization.</p>
    </li>
    <li>
    <p><strong>Business Intelligence and Analytics:</strong> Building data analytics platforms, dashboards, and reporting tools that provide actionable insights, predictive analytics, and data-driven decision support for strategic planning and performance optimization.</p>
    </li>
    <li>
    <p><strong>Cloud-Based Solutions:</strong> Leveraging cloud infrastructure and services (such as AWS, Azure, Google Cloud) to deploy scalable, secure, and cost-effective enterprise applications, databases, and collaboration tools across geographically distributed teams.</p>
    </li>
    <li>
    <p><strong>Security and Compliance:</strong> Implementing robust cybersecurity measures, access controls, data encryption, and compliance frameworks (such as GDPR, HIPAA, PCI DSS) to protect sensitive data, mitigate risks, and ensure regulatory compliance.</p>
    </li>
    </ul>
    <h3>Why Choose Us for Enterprise Solutions?</h3>
    <ul>
    <li>
    <p><strong>Industry Expertise:</strong> Our team comprises industry experts, solution architects, developers, and project managers with deep domain knowledge across various sectors, including manufacturing, healthcare, finance, retail, and more.</p>
    </li>
    <li>
    <p><strong>Scalability and Flexibility:</strong> We design scalable and flexible solutions that can adapt to evolving business needs, accommodate growth, and integrate seamlessly with existing IT infrastructure and legacy systems.</p>
    </li>
    <li>
    <p><strong>Collaborative Approach:</strong> We work closely with your stakeholders, IT teams, and end-users to understand your business objectives, workflows, pain points, and user requirements, ensuring alignment and successful solution delivery.</p>
    </li>
    <li>
    <p><strong>Continuous Support and Maintenance:</strong> We provide ongoing support, maintenance, updates, and training to ensure optimal performance, security, and usability of your enterprise solutions throughout their lifecycle.</p>
    </li>
    </ul>
    <h3>Transform Your Enterprise with Us</h3>
    <p>Partner with us to unlock the full potential of enterprise solutions, improve operational efficiency, drive innovation, and stay ahead in today's competitive landscape. Whether you are a large corporation, government entity, or multinational organization, our tailored enterprise solutions can help you achieve strategic objectives and business success.</p>
    <p>Contact us today to discuss your enterprise solution needs and embark on a transformative journey with our expertise.</p>`,
    featuredImage: 'enterprise-solutions.jpeg'
  },
  {
    id: 6,
    icon: 'fa fa-shopping-cart',
    name: 'E-commerce Solutions',
    description: 'Creating seamless and scalable e-commerce platforms to drive online growth and customer satisfaction.',
    pageLink: 'e-commerce-solutions',
    pageDescription: `<p>Welcome to our E-commerce Solutions, where innovation meets online success. At Opus Infotech, we specialize in crafting tailored e-commerce solutions that empower businesses to thrive in the digital marketplace. Whether you&apos;re launching a new e-commerce venture or seeking to revamp an existing platform, we have the expertise and tools to help you achieve your sales and growth objectives.</p>
    <h3>Our E-commerce Solutions Offerings:</h3>
    <ul>
        <li>
            <p><strong>Custom E-commerce Development:</strong> Building scalable, feature-rich e-commerce websites and applications tailored to your unique business requirements and target audience.</p>
        </li>
        <li>
            <p><strong>Platform Integration:</strong> Seamlessly integrating popular e-commerce platforms such as Shopify, WooCommerce, Magento, and custom solutions with your backend systems, payment gateways, and third-party services.</p>
        </li>
        <li>
            <p><strong>Mobile Commerce (M-commerce):</strong> Optimizing your e-commerce platform for mobile devices, ensuring a seamless shopping experience across smartphones and tablets.</p>
        </li>
        <li>
            <p><strong>E-commerce UX/UI Design:</strong> Designing intuitive, visually appealing user interfaces (UI) and user experiences (UX) to enhance engagement, conversions, and customer satisfaction.</p>
        </li>
        <li>
            <p><strong>Payment Gateway Integration:</strong> Integrating secure payment gateways, enabling smooth and secure online transactions for your customers.</p>
        </li>
        <li>
            <p><strong>E-commerce SEO and Digital Marketing:</strong> Implementing e-commerce SEO strategies, optimizing product listings, and leveraging digital marketing techniques to drive traffic, leads, and sales.</p>
        </li>
    </ul>
    <h3>Why Choose Us for E-commerce Solutions?</h3>
    <ul>
        <li>
            <p><strong>Expertise:</strong> Our team comprises experienced developers, designers, and digital marketers with proven expertise in e-commerce technologies, trends, and best practices.</p>
        </li>
        <li>
            <p><strong>Scalability:</strong> Our solutions are designed to scale with your business growth, accommodating increased traffic, products, and functionalities as your e-commerce operations expand.</p>
        </li>
        <li>
            <p><strong>Conversion Optimization:</strong> Leveraging data-driven insights, A/B testing, and analytics to optimize conversion rates, cart abandonment rates, and overall sales performance.</p>
        </li>
        <li>
            <p><strong>Security and Compliance:</strong> Implementing robust security measures, SSL encryption, PCI DSS compliance, and fraud prevention solutions to protect customer data and transactions.</p>
        </li>
    </ul>
    <h3>Elevate Your E-commerce Experience with Us</h3>
    <p>Partner with us to transform your e-commerce vision into reality. From initial consultation and strategy development to implementation, optimization, and ongoing support, we are committed to delivering e-commerce solutions that drive results and exceed expectations.</p>
    <p>Contact us today to discuss your e-commerce goals and discover how we can accelerate your online success.</p>`,
    featuredImage: 'e-commerce-solutions.jpeg'
  },
  {
    id: 7,
    icon: 'fas fa-cloud',
    name: 'Cloud Solutions',
    description: 'Empowering businesses with scalable and secure cloud-based solutions for enhanced agility and performance.',
    pageLink: 'cloud-solutions',
    pageDescription: `<p>Welcome to our Cloud Solutions services, where agility meets scalability in the digital realm. At Opus Infotech, we specialize in delivering innovative cloud solutions that empower businesses to harness the full potential of cloud technologies. Whether you are looking to migrate to the cloud, optimize your existing cloud infrastructure, or leverage cloud-native services, we have the expertise and experience to guide you on your cloud journey.</p>
    <h3>Our Cloud Solutions Offerings:</h3>
    <ul>
        <li>
            <p><strong>Cloud Migration Services:</strong> Seamless migration of applications, data, and workloads to leading cloud platforms such as Amazon Web Services (AWS), Microsoft Azure, Google Cloud Platform (GCP), and more.</p>
        </li>
        <li>
            <p><strong>Cloud Infrastructure Management:</strong> Proactive monitoring, management, and optimization of cloud infrastructure to ensure performance, security, and cost-efficiency.</p>
        </li>
        <li>
            <p><strong>Hybrid and Multi-Cloud Solutions:</strong> Designing and implementing hybrid cloud or multi-cloud architectures to leverage the strengths of different cloud providers while ensuring interoperability and data consistency.</p>
        </li>
        <li>
            <p><strong>Cloud Security and Compliance:</strong> Implementing robust cloud security measures, access controls, data encryption, and compliance frameworks (such as GDPR, HIPAA) to protect sensitive data and ensure regulatory compliance.</p>
        </li>
        <li>
            <p><strong>Serverless Computing and Containers:</strong> Leveraging serverless computing platforms (like AWS Lambda, Azure Functions) and containerization technologies (such as Docker, Kubernetes) for efficient, scalable, and cost-effective application deployments.</p>
        </li>
        <li>
            <p><strong>Cloud Backup and Disaster Recovery:</strong> Designing and implementing backup, disaster recovery (DR), and business continuity plans leveraging cloud-based solutions for data resilience and uninterrupted operations.</p>
        </li>
    </ul>
    <h3>Why Choose Us for Cloud Solutions?</h3>
    <ul>
        <li>
            <p><strong>Expertise and Certifications:</strong> Our team comprises certified cloud architects, engineers, and developers with in-depth knowledge and hands-on experience across major cloud platforms.</p>
        </li>
        <li>
            <p><strong>Scalability and Flexibility:</strong> Our cloud solutions are designed to scale seamlessly with your business growth, providing flexibility to adapt to changing demands and workloads.</p>
        </li>
        <li>
            <p><strong>Cost Optimization:</strong> Implementing cost optimization strategies, resource right-sizing, and utilization monitoring to ensure optimal cloud spending and ROI for your cloud investments.</p>
        </li>
        <li>
            <p><strong>Collaborative Approach:</strong> We work collaboratively with your team to understand your business goals, technical requirements, and constraints, delivering customized cloud solutions that align with your objectives.</p>
        </li>
    </ul>
    <h3>Unlock the Power of Cloud with Us</h3>
    <p>Partner with us to unlock the full potential of cloud technologies for your business. Whether you are a startup, SMB, or enterprise, our tailored cloud solutions can drive innovation, agility, and competitive advantage in today&apos;s digital landscape.</p>
    <p>Contact us today to schedule a consultation and embark on your cloud transformation journey.</p>`,
    featuredImage: 'cloud-solutions.jpeg'
  },
  {
    id: 8,
    icon: 'fa fa-database',
    name: 'Database Solutions',
    description: 'Robust database solutions tailored for efficient data management and scalability.',
    pageLink: 'database-solutions',
    pageDescription: `<p>Welcome to our Database Solutions services, where data meets efficiency and reliability. At Opus Infotech, we specialize in providing comprehensive database solutions tailored to meet the evolving needs of modern businesses. Whether you require database design and implementation, optimization of existing databases, or data migration services, we have the expertise and experience to support your data management initiatives.</p>
    <h3>Our Database Solutions Offerings:</h3>
    <ul>
        <li>
            <p><strong>Database Design and Development:</strong> Designing and building scalable and efficient database architectures based on your specific business requirements, data models, and application needs.</p>
        </li>
        <li>
            <p><strong>Database Management and Administration:</strong> Proactive monitoring, management, and maintenance of databases to ensure optimal performance, availability, and data integrity.</p>
        </li>
        <li>
            <p><strong>Data Migration and Integration:</strong> Seamless migration of data between different database systems, platforms, or cloud environments while ensuring data consistency, security, and minimal downtime.</p>
        </li>
        <li>
            <p><strong>Performance Tuning and Optimization:</strong> Fine-tuning database configurations, indexing strategies, query optimization, and resource utilization to enhance performance and reduce latency.</p>
        </li>
        <li>
            <p><strong>High Availability and Disaster Recovery:</strong> Implementing high availability (HA) solutions, failover mechanisms, and disaster recovery (DR) strategies to ensure continuous access to data and minimize downtime in case of failures or disasters.</p>
        </li>
        <li>
            <p><strong>Data Security and Compliance:</strong> Implementing robust data encryption, access controls, auditing mechanisms, and compliance measures (such as GDPR, HIPAA) to protect sensitive data and ensure regulatory compliance.</p>
        </li>
    </ul>
    <h3>Why Choose Us for Database Solutions?</h3>
    <ul>
        <li>
            <p><strong>Expertise and Experience:</strong> Our team comprises experienced database architects, administrators, and developers with expertise across various database technologies, including relational databases (SQL), NoSQL databases, and cloud databases.</p>
        </li>
        <li>
            <p><strong>Scalability and Flexibility:</strong> Our database solutions are designed to scale seamlessly with your data growth and evolving business needs, providing flexibility to adapt to changing requirements.</p>
        </li>
        <li>
            <p><strong>Cost Efficiency:</strong> Implementing cost-effective database solutions, optimizing resource utilization, and leveraging cloud-based database services to reduce infrastructure costs and maximize ROI.</p>
        </li>
        <li>
            <p><strong>Collaborative Partnership:</strong> We collaborate closely with your IT teams, stakeholders, and data analysts to understand your data ecosystem, goals, and challenges, delivering tailored solutions that align with your business objectives.</p>
        </li>
    </ul>
    <h3>Optimize Your Data Management with Us</h3>
    <p>Partner with us to optimize your data management processes, improve data reliability, and unlock actionable insights from your databases. Whether you are a small business, enterprise, or a growing startup, our database solutions can empower you to make informed decisions and drive business success.</p>
    <p>Contact us today to discuss your database requirements and start harnessing the power of data with our expertise.</p>`,
    featuredImage: 'database-solutions.jpeg'
  },
  {
    id: 9,
    icon: 'fa fa-mobile-alt',
    name: 'Mobile App Development',
    description: 'Building innovative and user-friendly mobile applications for iOS and Android platforms.',
    pageLink: 'mobile-app-development',
    pageDescription: `<p>Welcome to our Mobile App Development services, where innovation meets user experience. At Opus Infotech, we specialize in creating high-performance and user-friendly mobile applications that engage audiences, solve business challenges, and drive results. Whether you need a native app for iOS, Android, or cross-platform solutions, our experienced team of developers, designers, and testers is here to turn your ideas into reality.</p>
    <h3>Our Mobile App Development Offerings:</h3>
    <ul>
    <li>
    <p><strong>Native App Development:</strong> Building custom native apps for iOS (using Swift or Objective-C) and Android (using Java or Kotlin) platforms, ensuring optimal performance, seamless integration with device features, and a native user experience.</p>
    </li>
    <li>
    <p><strong>Cross-Platform Development:</strong> Developing cross-platform mobile apps using frameworks like React Native, Flutter, Xamarin, or Ionic, allowing code reusability across platforms while maintaining native-like performance and UI/UX design.</p>
    </li>
    <li>
    <p><strong>UI/UX Design:</strong> Creating intuitive and visually appealing app interfaces with a focus on user experience (UX), usability testing, interactive prototypes, and seamless navigation to enhance user engagement and satisfaction.</p>
    </li>
    <li>
    <p><strong>Backend Development and APIs:</strong> Designing and implementing scalable backend systems, databases, and APIs (Application Programming Interfaces) to support app functionalities such as data storage, user authentication, push notifications, and integrations with third-party services.</p>
    </li>
    <li>
    <p><strong>Quality Assurance and Testing:</strong> Conducting rigorous testing, including functional testing, performance testing, usability testing, and device compatibility testing, to ensure app stability, security, and optimal performance across different devices and OS versions.</p>
    </li>
    <li>
    <p><strong>App Store Deployment:</strong> Assisting in app store submission, review processes, and ongoing maintenance updates to ensure compliance with platform guidelines (Apple App Store, Google Play Store) and deliver a seamless user experience.</p>
    </li>
    </ul>
    <h3>Why Choose Us for Mobile App Development?</h3>
    <ul>
    <li>
    <p><strong>Technical Expertise:</strong> Our team comprises skilled mobile app developers, UI/UX designers, QA engineers, and project managers with expertise across various platforms, frameworks, and industry domains.</p>
    </li>
    <li>
    <p><strong>User-Centric Approach:</strong> We prioritize user feedback, market research, and usability testing to create apps that resonate with target audiences, meet business goals, and deliver a memorable user experience.</p>
    </li>
    <li>
    <p><strong>Agile Development Methodology:</strong> We follow agile development practices, iterative development cycles, and collaborative project management tools to ensure transparency, flexibility, and on-time delivery of app milestones.</p>
    </li>
    <li>
    <p><strong>Post-Launch Support:</strong> We provide ongoing support, maintenance, app updates, and performance monitoring to address bugs, enhance features, optimize app performance, and ensure user satisfaction post-launch.</p>
    </li>
    </ul>
    <h3>Transform Your Ideas into Apps with Us</h3>
    <p>Partner with us to transform your app ideas into reality and reach your target audience on their mobile devices. Whether you are a startup, SMB, or enterprise, our customized mobile app development services can help you stand out in the competitive app market and achieve business success.</p>
    <p>Contact us today to discuss your mobile app development needs and embark on a successful app journey with our expertise.</p>`,
    featuredImage: 'mobile-app-development.jpeg'
  },
  {
    id: 10,
    icon: 'fa fa-code',
    name: 'Web Development',
    description: 'Crafting responsive and feature-rich web solutions for seamless online experiences.',
    pageLink: 'web-development',
    pageDescription: `<p>Welcome to our Web Development services, where creativity meets functionality. At Opus Infotech, we are passionate about crafting engaging and impactful web solutions that elevate your online presence and drive results. Whether you need a stunning website, a robust web application, or e-commerce platform, we have the skills and experience to bring your vision to life.</p>
    <h3>Our Web Development Offerings:</h3>
    <ul>
        <li>
            <p><strong>Custom Website Development:</strong> Creating bespoke websites tailored to your brand identity, target audience, and business goals using the latest web technologies such as HTML5, CSS3, and JavaScript frameworks.</p>
        </li>
        <li>
            <p><strong>Content Management Systems (CMS):</strong> Developing and customizing CMS platforms such as WordPress, Drupal, and Joomla for easy content management, scalability, and flexibility.</p>
        </li>
        <li>
            <p><strong>Web Application Development:</strong> Building dynamic and interactive web applications using frameworks like React.js, Angular, Vue.js, and backend technologies such as Node.js, Python (Django/Flask), or PHP (Laravel).</p>
        </li>
        <li>
            <p><strong>E-commerce Development:</strong> Designing and developing secure and user-friendly e-commerce platforms using platforms like Shopify, WooCommerce, Magento, or custom solutions tailored to your business needs.</p>
        </li>
        <li>
            <p><strong>Mobile-Responsive Design:</strong> Ensuring seamless user experiences across devices with responsive and mobile-friendly designs that adapt to various screen sizes and orientations.</p>
        </li>
        <li>
            <p><strong>Performance Optimization:</strong> Optimizing website speed, performance, and loading times through code optimization, caching strategies, and image compression techniques.</p>
        </li>
    </ul>
    <h3>Why Choose Us for Web Development?</h3>
    <ul>
        <li>
            <p><strong>Expertise and Experience:</strong> Our team comprises skilled web developers, designers, and UX/UI specialists with extensive experience across diverse industries and technologies.</p>
        </li>
        <li>
            <p><strong>Creative Design Approach:</strong> Blending aesthetics with functionality, we create visually appealing and user-centric designs that enhance brand perception and user engagement.</p>
        </li>
        <li>
            <p><strong>SEO and Digital Marketing Integration:</strong> Implementing SEO-friendly practices, meta tags, structured data, and analytics integration to enhance search engine visibility and track performance metrics.</p>
        </li>
        <li>
            <p><strong>Collaborative Process:</strong> We collaborate closely with clients throughout the development process, from initial concept discussions and wireframing to design iterations, development, testing, and launch.</p>
        </li>
    </ul>
    <h3>Elevate Your Online Presence with Us</h3>
    <p>Partner with us to create compelling web solutions that captivate audiences, drive conversions, and achieve your business objectives online. Whether you are a startup, SMB, or enterprise, our customized web development services can meet your unique needs and exceed your expectations.</p>
    <p>Contact us today to discuss your web development project and take the first step toward an exceptional online presence.</p>`,
    featuredImage: 'web-development.jpeg'
  },
  {
    id: 11,
    icon: 'fa fa-pencil-ruler',
    name: 'UI/UX Design',
    description: 'Elevate user experiences with intuitive and visually appealing design solutions.',
    pageLink: 'ui-ux-design',
    pageDescription: `<p>Welcome to our UI/UX Design services, where creativity meets user-centric design. At Opus Infotech, we specialize in crafting seamless and engaging user experiences that resonate with your audience and drive business success. Whether you need a stunning website, a user-friendly app interface, or interactive digital prototypes, we have the skills and passion to bring your ideas to life.</p>
    <h3>Our UI/UX Design Offerings:</h3>
    <ul>
    <li>
    <p><strong>User Research and Analysis:</strong> Conducting in-depth user research, persona development, and usability testing to understand user behaviors, pain points, and preferences.</p>
    </li>
    <li>
    <p><strong>Wireframing and Prototyping:</strong> Creating low-fidelity wireframes and interactive prototypes to visualize user flows, navigation structures, and interface interactions early in the design process.</p>
    </li>
    <li>
    <p><strong>UI Design and Branding:</strong> Designing visually appealing and cohesive user interfaces (UI) that align with your brand identity, using typography, color schemes, icons, and imagery to create memorable experiences.</p>
    </li>
    <li>
    <p><strong>Responsive Design:</strong> Ensuring seamless user experiences across devices and screen sizes through responsive design principles and testing on various platforms and browsers.</p>
    </li>
    <li>
    <p><strong>Accessibility and Usability:</strong> Implementing accessibility standards (WCAG) and best practices to ensure inclusivity and usability for all users, including those with disabilities.</p>
    </li>
    <li>
    <p><strong>Interaction Design:</strong> Enhancing user engagement and interactivity through intuitive interface elements, animations, micro-interactions, and feedback mechanisms.</p>
    </li>
    </ul>
    <h3>Why Choose Us for UI/UX Design?</h3>
    <ul>
    <li>
    <p><strong>Design Expertise:</strong> Our team comprises experienced UI/UX designers, researchers, and usability experts with a deep understanding of design principles, trends, and user psychology.</p>
    </li>
    <li>
    <p><strong>User-Centered Approach:</strong> We place users at the center of our design process, focusing on creating meaningful and delightful experiences that meet user needs and expectations.</p>
    </li>
    <li>
    <p><strong>Collaborative Process:</strong> We collaborate closely with clients, stakeholders, and development teams to align design goals with business objectives, ensuring a seamless transition from design to implementation.</p>
    </li>
    <li>
    <p><strong>Iterative Design Process:</strong> We follow an iterative design approach, gathering feedback, conducting usability testing, and refining designs to continuously improve user satisfaction and product usability.</p>
    </li>
    </ul>
    <h3>Transform Your Digital Experience with Us</h3>
    <p>Partner with us to elevate your digital presence, enhance user satisfaction, and achieve your business goals through exceptional UI/UX design. Whether you are launching a new product or improving an existing interface, our customized design solutions can make a difference.</p>
    <p>Contact us today to discuss your UI/UX design needs and start creating impactful digital experiences.</p>`,
    featuredImage: 'ui-ux-design.jpeg'
  },
  {
    id: 12,
    icon: 'fa fa-lightbulb',
    name: 'Software Consulting',
    description: 'Expert guidance and strategic insights to optimize your software projects and technology initiatives.',
    pageLink: 'software-consulting',
    pageDescription: `<p>Welcome to our Software Consulting services, where expertise meets innovation. At Opus Infotech, we offer comprehensive software consulting services to help businesses navigate the complexities of technology, optimize their processes, and achieve their digital transformation goals. Whether you need strategic advice, technical expertise, or project management support, our team of experienced consultants is here to guide you every step of the way.</p>
    <h3>Our Software Consulting Offerings:</h3>
    <ul>
    <li>
    <p><strong>Technology Strategy and Roadmap:</strong> Assessing your current technology landscape, identifying opportunities for improvement, and developing strategic technology roadmaps aligned with your business objectives.</p>
    </li>
    <li>
    <p><strong>Digital Transformation:</strong> Providing insights and recommendations for leveraging emerging technologies such as AI, machine learning, cloud computing, IoT, and blockchain to drive innovation and competitive advantage.</p>
    </li>
    <li>
    <p><strong>Software Architecture and Design:</strong> Designing scalable, secure, and robust software architectures that align with industry best practices, standards, and future scalability requirements.</p>
    </li>
    <li>
    <p><strong>Project Management and Agile Practices:</strong> Implementing agile methodologies, project management frameworks (such as Scrum, Kanban), and DevOps practices to streamline development processes, improve collaboration, and accelerate time-to-market.</p>
    </li>
    <li>
    <p><strong>IT Infrastructure Optimization:</strong> Evaluating and optimizing IT infrastructure, including cloud migrations, server deployments, network configurations, and cybersecurity measures to enhance performance, reliability, and security.</p>
    </li>
    <li>
    <p><strong>Vendor Selection and Management:</strong> Assisting in vendor evaluation, selection, and contract negotiations for software solutions, IT services, and technology partnerships.</p>
    </li>
    </ul>
    <h3>Why Choose Us for Software Consulting?</h3>
    <ul>
    <li>
    <p><strong>Experienced Consultants:</strong> Our team comprises seasoned software consultants, architects, project managers, and industry experts with diverse experience across various domains and technologies.</p>
    </li>
    <li>
    <p><strong>Strategic Partnerships:</strong> We work as trusted partners with our clients, understanding their business challenges, goals, and constraints to deliver tailored solutions that drive measurable business outcomes.</p>
    </li>
    <li>
    <p><strong>Innovative Solutions:</strong> We stay updated with the latest trends, tools, and technologies to recommend and implement innovative solutions that address current challenges and future-proof your technology investments.</p>
    </li>
    <li>
    <p><strong>Collaborative Approach:</strong> We collaborate closely with your internal teams, stakeholders, and third-party vendors to ensure alignment, transparency, and successful project delivery.</p>
    </li>
    </ul>
    <h3>Transform Your Technology Landscape with Us</h3>
    <p>Partner with us to harness the power of technology, optimize your software ecosystem, and unlock new opportunities for growth and efficiency. Whether you are a startup, SMB, or enterprise, our software consulting services can help you stay ahead in today's dynamic digital landscape.</p>
    <p>Contact us today to discuss your software consulting needs and embark on a successful technology journey with us.</p>`,
    featuredImage: 'software-consulting.jpeg'
  },
  {
    id: 13,
    icon: 'fa fa-code',
    name: 'Custom Software Development',
    description: 'Tailored software solutions designed to meet your unique business needs and goals.',
    pageLink: 'custom-software-development',
    pageDescription: `<p>Welcome to our Custom Software Development services, where innovation meets precision. At Opus Infotech, we specialize in designing and developing custom software solutions that address unique business challenges, streamline operations, and drive growth. Whether you need a standalone application, enterprise software, or a complex system integration, our team of experienced developers and engineers is ready to bring your vision to reality.</p>
    <h3>Our Custom Software Development Offerings:</h3>
    <ul>
    <li>
    <p><strong>Custom Application Development:</strong> Building bespoke software applications tailored to your business processes, workflows, and user requirements using cutting-edge technologies and development frameworks.</p>
    </li>
    <li>
    <p><strong>Enterprise Software Solutions:</strong> Designing and implementing scalable and robust enterprise-level software solutions such as CRM systems, ERP systems, inventory management systems, and more to streamline operations and improve productivity.</p>
    </li>
    <li>
    <p><strong>Mobile App Development:</strong> Developing native or cross-platform mobile applications for iOS, Android, or hybrid platforms, ensuring seamless user experiences and leveraging device-specific features.</p>
    </li>
    <li>
    <p><strong>Legacy System Modernization:</strong> Upgrading and modernizing legacy software systems, migrating to newer platforms, improving scalability, security, and performance while preserving critical business functionalities.</p>
    </li>
    <li>
    <p><strong>Cloud-Based Solutions:</strong> Designing and deploying cloud-native applications, leveraging cloud infrastructure and services (such as AWS, Azure, Google Cloud) for scalability, flexibility, and cost-effectiveness.</p>
    </li>
    <li>
    <p><strong>Integration Services:</strong> Integrating disparate systems, databases, APIs, and third-party services to create unified and streamlined workflows, improve data visibility, and enhance business intelligence.</p>
    </li>
    </ul>
    <h3>Why Choose Us for Custom Software Development?</h3>
    <ul>
    <li>
    <p><strong>Technical Expertise:</strong> Our team comprises skilled software architects, developers, UX/UI designers, and quality assurance engineers with expertise across a wide range of technologies, platforms, and industries.</p>
    </li>
    <li>
    <p><strong>Collaborative Approach:</strong> We work closely with your stakeholders, subject matter experts, and end-users to understand your business goals, requirements, and challenges, ensuring alignment throughout the development lifecycle.</p>
    </li>
    <li>
    <p><strong>Agile Development Methodology:</strong> We follow agile development practices, iterative delivery cycles, and continuous feedback loops to ensure transparency, flexibility, and on-time project delivery.</p>
    </li>
    <li>
    <p><strong>Quality Assurance:</strong> Rigorous testing, code reviews, and quality assurance processes to ensure software reliability, performance, security, and compliance with industry standards and best practices.</p>
    </li>
    </ul>
    <h3>Transform Your Business with Custom Software Solutions</h3>
    <p>Partner with us to transform your ideas into powerful and scalable software solutions that drive innovation, efficiency, and competitive advantage. Whether you are a startup, SMB, or enterprise, our custom software development services can help you achieve your digital transformation goals.</p>
    <p>Contact us today to discuss your custom software development needs and start building the software that fuels your success.</p>`,
    featuredImage: 'custom-software-development.jpeg'
  },
  {
    id: 14,
    icon: 'fa fa-bullhorn',
    name: 'Digital Marketing',
    description: 'Driving online visibility and engagement through strategic digital marketing solutions.',
    pageLink: 'digital-marketing',
    pageDescription: `<p>Welcome to our Digital Marketing services, where strategy meets results. At Opus Infotech, we specialize in creating data-driven digital marketing campaigns that elevate brands, generate leads, and maximize ROI. Whether you need help with search engine optimization (SEO), pay-per-click (PPC) advertising, social media marketing, or content marketing, our experienced team of digital marketers is here to help you achieve your marketing goals.</p>
    <h3>Our Digital Marketing Offerings:</h3>
    <ul>
    <li>
    <p><strong>Search Engine Optimization (SEO):</strong> Optimizing your website's content, structure, and backend elements to improve organic search visibility, drive targeted traffic, and enhance search engine rankings across platforms like Google, Bing, and Yahoo.</p>
    </li>
    <li>
    <p><strong>Pay-Per-Click (PPC) Advertising:</strong> Creating and managing targeted PPC campaigns on platforms such as Google Ads, Bing Ads, and social media channels to reach potential customers based on keywords, demographics, interests, and behaviors.</p>
    </li>
    <li>
    <p><strong>Social Media Marketing (SMM):</strong> Developing and executing social media strategies across platforms like Facebook, Instagram, Twitter, LinkedIn, and TikTok to build brand awareness, engage audiences, and drive website traffic and conversions.</p>
    </li>
    <li>
    <p><strong>Content Marketing:</strong> Creating high-quality, relevant, and valuable content assets such as blogs, infographics, videos, and eBooks to attract and engage your target audience, establish thought leadership, and drive organic traffic.</p>
    </li>
    <li>
    <p><strong>Email Marketing:</strong> Designing and implementing email campaigns, newsletters, and automated workflows to nurture leads, promote products/services, drive conversions, and build long-term customer relationships.</p>
    </li>
    <li>
    <p><strong>Analytics and Reporting:</strong> Monitoring campaign performance, tracking key metrics such as website traffic, conversion rates, ROI, and customer engagement, and providing actionable insights for continuous optimization and improvement.</p>
    </li>
    </ul>
    <h3>Why Choose Us for Digital Marketing?</h3>
    <ul>
    <li>
    <p><strong>Experienced Team:</strong> Our digital marketing team comprises experienced strategists, analysts, content creators, and advertising specialists with a track record of delivering successful campaigns across diverse industries.</p>
    </li>
    <li>
    <p><strong>Data-Driven Approach:</strong> We leverage data analytics, market research, and industry insights to develop tailored digital marketing strategies that align with your business objectives, target audience, and competitive landscape.</p>
    </li>
    <li>
    <p><strong>Holistic Solutions:</strong> We offer a comprehensive suite of digital marketing services, from strategy development and campaign execution to performance tracking, optimization, and continuous improvement, ensuring a holistic approach to your marketing efforts.</p>
    </li>
    <li>
    <p><strong>Transparent Communication:</strong> We believe in transparent communication, regular reporting, and collaborative decision-making, keeping you informed and involved throughout the campaign lifecycle.</p>
    </li>
    </ul>
    <h3>Elevate Your Digital Presence with Us</h3>
    <p>Partner with us to unlock the full potential of digital marketing and achieve measurable results for your business. Whether you are a startup, SMB, or enterprise, our customized digital marketing services can help you reach your target audience, drive conversions, and grow your brand online.</p>
    <p>Contact us today to discuss your digital marketing needs and take your online presence to the next level with our expertise.</p>`,
    featuredImage: 'digital-marketing.jpeg'
  }
];