<!-- Topbar Start -->
<div id="top" class="container-fluid bg-dark px-5 d-none d-lg-block">
  <div class="row gx-0">
    <div class="col-lg-8 text-center text-lg-start mb-2 mb-lg-0">
      <div class="d-inline-flex align-items-center" style="height: 45px;">
        <small class="me-3 text-light"><a class="text-light"
            href="https://maps.app.goo.gl/h9bhixRsV7cci3xH7"
            target="_blank"><i class="fa fa-map-marker-alt me-2"></i>21A-985, Patil Nagar, Umarkhed, Yavatmal, Maharashtra, India-445206</a></small>
        <small class="me-3 text-light"><a class="text-light" href="tel:+919975608443" target="_blank"><i
              class="fa fa-phone-alt me-2"></i>+91-9975608443</a></small>
        <small class="text-light"><a class="text-light"
            href="mailto:info@opusinfotech.co.in?subject=OpusInfotech - Enquiry" target="_blank"><i
              class="fa fa-envelope-open me-2"></i>info&#64;opusinfotech.co.in</a></small>
      </div>
    </div>
    <!-- <div class="col-lg-4 text-center text-lg-end">
      <div class="d-inline-flex align-items-center" style="height: 45px;">
        <a class="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" href=""><i
            class="fab fa-twitter fw-normal"></i></a>
        <a class="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" href=""><i
            class="fab fa-facebook-f fw-normal"></i></a>
        <a class="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" href=""><i
            class="fab fa-linkedin-in fw-normal"></i></a>
        <a class="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" href=""><i
            class="fab fa-instagram fw-normal"></i></a>
        <a class="btn btn-sm btn-outline-light btn-sm-square rounded-circle" href=""><i
            class="fab fa-youtube fw-normal"></i></a>
      </div>
    </div> -->
  </div>
</div>
<!-- Topbar End -->


<!-- Navbar & Carousel Start -->
<div class="container-fluid position-relative p-0">
  <nav class="navbar navbar-expand-lg navbar-dark px-5 py-3 py-lg-0">
    <a routerLink="/home" (click)="updateValue('home')" routerLinkActive="active" class="navbar-brand p-0">
      <img class="w-100" style="border-radius: 5px;" src="assets/img/opusinfotech-logo.jpg" alt="">
      <!-- <h1 class="m-0"><i class="fa fa-cubes me-2"></i>OpusInfotech</h1> -->
    </a>
    <button class="navbar-toggler" type="button" (click)="toggleNavbar()">
      <span class="fas" [class.fa-times]="isNavbarCollapsed" [class.fa-bars]="!isNavbarCollapsed"></span>
    </button>
    <div class="collapse navbar-collapse" [class.show]="isNavbarCollapsed">
      <div class="navbar-nav ms-auto py-0">
        <a routerLink="/home" (click)="updateValue('home')" routerLinkActive="active" class="nav-item nav-link">Home</a>
        <a routerLink="/about" (click)="updateValue('about')" routerLinkActive="active"
          class="nav-item nav-link">About</a>
        <a routerLink="/careers" (click)="updateValue('careers')" routerLinkActive="active"
          class="nav-item nav-link">Careers</a>
        <a routerLink="/services" (click)="updateValue('services')" routerLinkActive="active"
          class="nav-item nav-link">Services</a>
        <a routerLink="/contact" (click)="updateValue('contact')" routerLinkActive="active"
          class="nav-item nav-link">Contact</a>
        <a routerLink="/quote" (click)="updateValue('quote')" routerLinkActive="active"
          class="btn btn-primary py-md-3 px-md-5 me-3 quote-btn">Free Quote</a>
      </div>

    </div>
  </nav>

  <div id="header-carousel" *ngIf="showCarousel" class="carousel slide carousel-fade" data-bs-ride="carousel">
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img class="w-100" src="assets/img/carousel-1.gif" alt="Image">
        <div class="carousel-caption d-flex flex-column align-items-center justify-content-center">
          <div class="p-3" style="max-width: 900px;">
            <h5 class="text-white text-uppercase mb-3 animated slideInDown">Pune IT Boutique</h5>
            <h1 class="display-1 text-white mb-md-4 animated zoomIn">We are a boutique IT solutions company based in
              Pune, India.</h1>
            <a routerLink="/quote" (click)="updateValue('quote')" routerLinkActive="active"
              class="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft">Free Quote</a>
            <a routerLink="/contact" (click)="updateValue('contact')" routerLinkActive="active"
              class="btn btn-outline-light py-md-3 px-md-5 animated slideInRight">Contact Us</a>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <img class="w-100" src="assets/img/carousel-2.gif" alt="Image">
        <div class="carousel-caption d-flex flex-column align-items-center justify-content-center">
          <div class="p-3" style="max-width: 900px;">
            <h5 class="text-white text-uppercase mb-3 animated slideInDown">Global Solutions</h5>
            <h1 class="display-1 text-white mb-md-4 animated zoomIn">We develop tailor-made IT solutions for our clients
              all across the world.</h1>
            <a routerLink="/quote" (click)="updateValue('quote')" routerLinkActive="active"
              class="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft">Free Quote</a>
            <a routerLink="/contact" (click)="updateValue('contact')" routerLinkActive="active"
              class="btn btn-outline-light py-md-3 px-md-5 animated slideInRight">Contact Us</a>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <img class="w-100" src="assets/img/carousel-3.gif" alt="Image">
        <div class="carousel-caption d-flex flex-column align-items-center justify-content-center">
          <div class="p-3" style="max-width: 900px;">
            <h5 class="text-white text-uppercase mb-3 animated slideInDown">Tailored Solutions</h5>
            <h1 class="display-1 text-white mb-md-4 animated zoomIn">We have a broad range of in-house expertise to
              develop a customized tangible solutions to help solve your business challenges.</h1>
            <a routerLink="/quote" (click)="updateValue('quote')" routerLinkActive="active"
              class="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft">Free Quote</a>
            <a routerLink="/contact" (click)="updateValue('contact')" routerLinkActive="active"
              class="btn btn-outline-light py-md-3 px-md-5 animated slideInRight">Contact Us</a>
          </div>
        </div>
      </div>
    </div>
    <button class="carousel-control-prev" type="button" data-bs-target="#header-carousel" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#header-carousel" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>

  <div *ngIf="!showCarousel" class="container-fluid bg-primary py-5 bg-header">
    <div class="row py-5">
      <div class="col-12 pt-lg-5 mt-lg-5 text-center">
        <h1 class="display-4 text-white animated zoomIn">{{selectedPage}}</h1>
        <a href="" class="h5 text-white">Home</a>
        <i class="far fa-circle text-white px-2"></i>
        <a href="" class="h5 text-white">{{selectedPage}}</a>
      </div>
    </div>
  </div>
  <!-- Navbar End -->

</div>
<!-- Navbar & Carousel End -->

<router-outlet></router-outlet>

<!-- Footer Start -->
<div class="container-fluid bg-dark text-light mt-5 wow fadeInUp" data-wow-delay="0.1s">
  <div class="container">
    <div class="row gx-5">
      <div class="col-lg-4 col-md-6 footer-about">
        <div class="d-flex flex-column align-items-center justify-content-center text-center h-100 bg-primary p-4">
          <a routerLink="/home" (click)="updateValue('home')" routerLinkActive="active" class="navbar-brand"
            style="max-width: 250px;">
            <img class="w-100" style="border-radius: 5px;" src="assets/img/opusinfotech-logo.jpg" alt="">
            <!-- <h1 class="m-0 text-white"><i class="fa fa-cubes me-2"></i>OpusInfotech</h1> -->
          </a>
          <p class="mt-3 mb-4">Building long-term relationships with our clients is Opus Infotech's number one
            priority. 95% of our customers return to us with new projects because they enjoy the quality of the software
            we create and the level of professionalism our employees bring to the table.</p>
          <!-- <form action="">
            <div class="input-group">
              <input type="text" class="form-control border-white p-3" placeholder="Your Email">
              <button class="btn btn-dark">Sign Up</button>
            </div>
          </form> -->
        </div>
      </div>
      <div class="col-lg-8 col-md-6">
        <div class="row gx-5">
          <div class="col-lg-4 col-md-12 pt-5 mb-5">
            <div class="section-title section-title-sm position-relative pb-3 mb-4">
              <h3 class="text-light mb-0">Get In Touch</h3>
            </div>
            <div class="d-flex mb-2">
              <i class="bi bi-geo-alt text-primary me-2"></i>
              <p class="mb-0"><a class="text-light"
                  href="https://maps.app.goo.gl/h9bhixRsV7cci3xH7"
                  target="_blank">21A-985, Patil Nagar, Umarkhed, Yavatmal, Maharashtra, India-445206</a></p>
            </div>
            <div class="d-flex mb-2">
              <i class="bi bi-envelope-open text-primary me-2"></i>
              <p class="mb-0"><a class="text-light"
                  href="mailto:info@opusinfotech.co.in?subject=OpusInfotech - Enquiry" target="_blank">
                  info&#64;opusinfotech.co.in</a></p>
            </div>
            <div class="d-flex mb-2">

              <i class="bi bi-telephone text-primary me-2"></i>
              <p class="mb-0"><a class="text-light" href="tel:+919975608443" target="_blank">+91-9975608443</a></p>
            </div>
            <!-- <div class="d-flex mt-4">
              <a class="btn btn-primary btn-square me-2" href="#"><i class="fab fa-twitter fw-normal"></i></a>
              <a class="btn btn-primary btn-square me-2" href="#"><i class="fab fa-facebook-f fw-normal"></i></a>
              <a class="btn btn-primary btn-square me-2" href="#"><i class="fab fa-linkedin-in fw-normal"></i></a>
              <a class="btn btn-primary btn-square" href="#"><i class="fab fa-instagram fw-normal"></i></a>
            </div> -->
          </div>
          <div class="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
            <div class="section-title section-title-sm position-relative pb-3 mb-4">
              <h3 class="text-light mb-0">Quick Links</h3>
            </div>
            <div class="link-animated d-flex flex-column justify-content-start">
              <a class="text-light mb-2" routerLink="/home" (click)="updateValue('home')"><i
                  class="bi bi-arrow-right text-primary me-2"></i>Home</a>
              <a class="text-light mb-2" routerLink="/about" (click)="updateValue('about')"><i
                  class="bi bi-arrow-right text-primary me-2"></i>About Us</a>
              <a class="text-light mb-2" routerLink="/services" (click)="updateValue('services')"><i
                  class="bi bi-arrow-right text-primary me-2"></i>Our Services</a>
              <a class="text-light" routerLink="/contact" (click)="updateValue('contact')"><i
                  class="bi bi-arrow-right text-primary me-2"></i>Contact Us</a>
            </div>
          </div>
          <div class="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
            <div class="section-title section-title-sm position-relative pb-3 mb-4">
              <h3 class="text-light mb-0">Popular Links</h3>
            </div>
            <div class="link-animated d-flex flex-column justify-content-start">
              <!-- <a class="text-light mb-2" routerLink="/team" (click)="updateValue('team')"><i
                  class="bi bi-arrow-right text-primary me-2"></i>Meet The Team</a> -->
              <a class="text-light mb-2" routerLink="/feature" (click)="updateValue('feature')"><i
                  class="bi bi-arrow-right text-primary me-2"></i>Our features</a>
              <!-- <a class="text-light mb-2" routerLink="/testimonial" (click)="updateValue('testimonial')"><i -->
              <!-- class="bi bi-arrow-right text-primary me-2"></i>Testimonial</a> -->
              <a class="text-light mb-2" routerLink="/quote" (click)="updateValue('quote')"><i
                  class="bi bi-arrow-right text-primary me-2"></i>Free Quote</a>
              <!-- <a class="text-light mb-2" routerLink="/plans" (click)="updateValue('plans')"><i
                  class="bi bi-arrow-right text-primary me-2"></i>Pricing Plans</a> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid text-white" style="background: #061429;">
  <div class="container text-center">
    <div class="row justify-content-end">
      <div class="col-lg-8 col-md-6">
        <div class="d-flex align-items-center justify-content-center" style="height: 75px;">
          <p class="mb-0">&copy; <a class="text-white border-bottom" href="#">OpusInfotech</a>. All Rights Reserved.

        </div>
      </div>
    </div>
  </div>
</div>
<!-- Footer End -->


<!-- Back to Top -->
<!-- <a href="#" class="btn btn-lg btn-primary btn-lg-square rounded back-to-top"><i class="bi bi-arrow-up"></i></a> -->