import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { IContact, IEmail } from '../../interfaces/common';
import { CommonService } from '../../services/common.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  contactForm!: FormGroup;
  contacts: IContact;
  constructor(private fb: FormBuilder, private commonService: CommonService, private titleService: Title) {
    this.contacts = {} as IContact;
  }

  ngOnInit(): void {
    this.commonService.setValue('contact');
    this.titleService.setTitle('Contact Us | Website and Software Development Companies in India | Opus Infotech');
    const keywords = 'enterprise software companies in india, customer software development, testing companies in pune, tech due diligence, united states it companies, custom application development company, enterprise software vendors, top Inventory software companies, top Inventory software company,trusted tech team, trusted tech team inc, us it companies, us it services companies, us it company, app development companies in pune,  Umarkhed it companies Yavatmal, Yavatmal software company list, usa it companies, Pune it companies';
    const description = 'Opus Infotech is a forward-thinking software company dedicated to revolutionizing industries through innovative technology solutions. Established in 2012, our team of passionate developers, designers, and entrepreneurs is committed to creating cutting-edge software products that drive efficiency, enhance productivity, and unlock new possibilities for businesses worldwide.';
    this.commonService.updateMetaTags(keywords, description);

    this.contactForm = this.fb.group({
      firstName: new FormControl(this.contacts.firstName, [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(30),
        Validators.pattern('^[a-zA-Z ]*$')
      ]),
      lastName: new FormControl(this.contacts.lastName, [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(30),
        Validators.pattern('^[a-zA-Z ]*$')
      ]),
      email: new FormControl(this.contacts.email, [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50),
        Validators.email,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')
      ]),
      contact: new FormControl(this.contacts.contact, [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10),
        Validators.pattern("^[0-9]*$")
      ]),
      company: new FormControl(this.contacts.company, [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(60)
      ]),
      message: new FormControl(''),
      orgId: new FormControl(3)
    });
  }

  async submit() {
    if (this.contactForm.invalid) {
      for (const control of Object.keys(this.contactForm.controls)) {
        this.contactForm.controls[control].markAsTouched();
      }
      return;
    }
    if (this.contactForm.valid) {
      await this.commonService.postContact(this.contactForm.value).subscribe({
        next: data => {
          alert("Thank you for showing interest in Opus Infotech! We will get back to you soon!");
        },
        error: err => {
          console.log(err);
        }
      });
      const emailToIndividual: IEmail = {
        body: `<div width="100%" style="margin:0;background-color:#f0f2f3">
        <div style="margin:auto;max-width:600px;padding-top:50px" class="m_-409804117361003443email-container">
          
          
          <table role="presentation" cellspacing="0" cellpadding="0" width="100%" align="center" id="m_-409804117361003443logoContainer" style="background:#252f3d;border-radius:3px 3px 0 0;max-width:600px">
            <tbody><tr>
              <td style="background:#091E3E;border-radius:3px 3px 0 0;padding:20px 0 10px 0;text-align:center">
                  <img src="https://www.opusinfotech.co.in/assets/img/opusinfotech-logo.jpg" alt="OpusInfotech logo" border="0" style="border-radius:5px;max-width:200px;background:#fff;" class="CToWUd" data-bit="iit">
              </td>
            </tr>
          </tbody></table>
          
          
          <table role="presentation" cellspacing="0" cellpadding="0" width="100%" align="center" id="m_-409804117361003443emailBodyContainer" style="border:0px;border-bottom:1px solid #d6d6d6;max-width:600px">
              <tbody><tr>
                <td style="background-color:#fff;color:#444;font-family:'Amazon Ember','Helvetica Neue',Roboto,Arial,sans-serif;font-size:14px;line-height:140%;padding:25px 35px">
                  <h1 style="font-size:20px;font-weight:bold;line-height:1.3;margin:0 0 15px 0">Enquiry Received via Contact Us Page</h1>
                  <p style="margin:0 0 15px 0;padding:0 0 0 0">Dear ${this.contactForm.value.firstName},</p>
                  <p style="margin:0 0 15px 0;padding:0 0 0 0">I hope this email finds you well. I am writing to inform you that we have received your enquiry through our Contact Us page.</p>
                  <p style="margin:0 0 15px 0;padding:0 0 0 0">We are currently reviewing the details provided and will get back to you shortly with further information or to address any questions or concerns you may have.</p>
                  <p style="margin:0 0 15px 0;padding:0 0 0 0">Thank you for reaching out to us. We appreciate your interest in <em>OpusInfotech</em>, and we look forward to assisting you further.</p>
                  <p>For more information about our service, please visit our website at <a href="https://opusinfotech.co.in" target="_blank" data-saferedirecturl="https://opusinfotech.co.in">www.opusinfotech.co.in</a>.</p>
                </td>
              </tr>
          <tr>
            <td style="background-color:#fff;border-top:1px solid #e0e0e0;color:#777;font-family:'Amazon Ember','Helvetica Neue',Roboto,Arial,sans-serif;font-size:14px;line-height:140%;padding:25px 35px">
                <p style="margin:0 0 15px 0;padding:0 0 0 0"><strong>Best regards,<br>OpusInfotech</strong><br><em>Phone</em>:&nbsp;<a href="tel:+919975608443" target="_blank"><i class="icon solid fa-mobile-alt" aria-hidden="true"></i>+91-9975608443</a><br><em>Email</em>:&nbsp;<a href="mailto:info@opusinfotech.co.in?subject=OpusInfotech - Enquiry" target="_blank">info@opusinfotech.co.in</a></p>
            </td>
          </tr>
          </tbody></table>
          <table role="presentation" cellspacing="0" cellpadding="0" width="100%" align="center" id="m_-409804117361003443footer" style="max-width:600px">
            <tbody><tr>
                <td style="color:#777;font-family:'Amazon Ember','Helvetica Neue',Roboto,Arial,sans-serif;font-size:12px;line-height:16px;padding:20px 30px;text-align:center">
                This email was generated automatically. Please refrain from responding directly to this message, as this inbox is not actively monitored for incoming replies. If you need assistance or have inquiries, please contact us through the appropriate channels as indicated on our website or in previous correspondences. Thank you!
                </td>
            </tr>
          </tbody></table>
        </div></div></div>`,
        recipient: this.contactForm.value.email,
        subject: 'Enquiry Received via Contact Us Page',
        fileName: '',
        orgId:1
      }
      const emailToOwnerData: IEmail = {
        body: `<div width="100%" style="margin:0;background-color:#f0f2f3">
        <div style="margin:auto;max-width:600px;padding-top:50px" class="m_-409804117361003443email-container">
          
          
          <table role="presentation" cellspacing="0" cellpadding="0" width="100%" align="center" id="m_-409804117361003443logoContainer" style="background:#252f3d;border-radius:3px 3px 0 0;max-width:600px">
            <tbody><tr>
              <td style="background:#091E3E;border-radius:3px 3px 0 0;padding:20px 0 10px 0;text-align:center">
                  <img src="https://www.opusinfotech.co.in/assets/img/opusinfotech-logo.jpg" alt="OpusInfotech logo" border="0" style="border-radius:5px;max-width:200px;background:#fff;" class="CToWUd" data-bit="iit">
              </td>
            </tr>
          </tbody></table>
          
          
          <table role="presentation" cellspacing="0" cellpadding="0" width="100%" align="center" id="m_-409804117361003443emailBodyContainer" style="border:0px;border-bottom:1px solid #d6d6d6;max-width:600px">
              <tbody><tr>
                <td style="background-color:#fff;color:#444;font-family:'Amazon Ember','Helvetica Neue',Roboto,Arial,sans-serif;font-size:14px;line-height:140%;padding:25px 35px">
                  <h1 style="font-size:20px;font-weight:bold;line-height:1.3;margin:0 0 15px 0">Enquiry Received via Contact Us Page</h1>
                  <p style="margin:0 0 15px 0;padding:0 0 0 0">Dear Gajanan,</p>
                  <p style="margin:0 0 15px 0;padding:0 0 0 0">I hope this email finds you well. I am writing to inform you that we have received an enquiry through our Contact Us page.</p>
              
          <table cellspacing="5px" cellpadding="5px">
                <tbody>
                <tr>
                <td><strong>Name</strong></td>
                <td>${this.contactForm.value.firstName} ${this.contactForm.value.lastName}</td>
                </tr>
                <tr style="height: 17px;">
                <td><strong>Email</strong></td>
                <td>${this.contactForm.value.email}</td>
                </tr>
                <tr style="height: 17px;">
                <td><strong>Contact Number</strong></td>
                <td>${this.contactForm.value.contact}</td>
                </tr>
                <tr style="height: 17px;">
                <td><strong>Company</strong></span></td>
                <td>${this.contactForm.value.company}</td>
                </tr>
                <tr style="height: 17px;">
                <td><strong>Message</strong></td>
                <td>${this.contactForm.value.message}</td>
                </tr>
                </tbody>
                </table>
                  <p>For more information about OpusInfotech, please visit our website at <a href="https://opusinfotech.co.in" target="_blank" data-saferedirecturl="https://opusinfotech.co.in">www.opusinfotech.co.in</a>.</p>
                </td>
              </tr>
          <tr>
            <td style="background-color:#fff;border-top:1px solid #e0e0e0;color:#777;font-family:'Amazon Ember','Helvetica Neue',Roboto,Arial,sans-serif;font-size:14px;line-height:140%;padding:25px 35px">
                <p style="margin:0 0 15px 0;padding:0 0 0 0"><strong>Best regards,<br>OpusInfotech</strong><br><em>Phone</em>:&nbsp;<a href="tel:+919975608443" target="_blank"><i class="icon solid fa-mobile-alt" aria-hidden="true"></i>+91-9975608443</a><br><em>Email</em>:&nbsp;<a href="mailto:info@opusinfotech.co.in?subject=OpusInfotech - Enquiry" target="_blank">info@opusinfotech.co.in</a></p>
            </td>
          </tr>
          </tbody></table>
          <table role="presentation" cellspacing="0" cellpadding="0" width="100%" align="center" id="m_-409804117361003443footer" style="max-width:600px">
            <tbody><tr>
                <td style="color:#777;font-family:'Amazon Ember','Helvetica Neue',Roboto,Arial,sans-serif;font-size:12px;line-height:16px;padding:20px 30px;text-align:center">
                This email was generated automatically. Please refrain from responding directly to this message, as this inbox is not actively monitored for incoming replies. If you need assistance or have inquiries, please contact us through the appropriate channels as indicated on our website or in previous correspondences. Thank you!
                </td>
            </tr>
          </tbody></table>
        </div></div></div>`,
        recipient: 'gajanan@opusinfotech.co.in',
        subject: 'Enquiry Received via Contact Us Page',
        fileName: '',
        orgId:1
      }
      await this.commonService.sendEmail(emailToIndividual)
        .subscribe(response => {
          console.log('Email sent successfully:', response);
        }, error => {
          console.error('Error sending email:', error);
        });

      await this.commonService.sendEmail(emailToOwnerData)
        .subscribe(response => {
          console.log('Email sent successfully:', response);
        }, error => {
          console.error('Error sending email:', error);
        });
    }
    this.contactForm.reset();
  }

  get firstName() {
    return this.contactForm.get('firstName')!;
  }

  get lastName() {
    return this.contactForm.get('lastName')!;
  }

  get email() {
    return this.contactForm.get('email')!;
  }
  get contact() {
    return this.contactForm.get('contact')!;
  }

  get company() {
    return this.contactForm.get('company')!;
  }
}
