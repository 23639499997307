import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PagesRoutingModule } from './pages-routing.module';
import { ServiceDetailComponent } from './service/service-detail/service-detail.component';

@NgModule({
  declarations: [
    ServiceDetailComponent
  ],
  imports: [
    RouterModule,
    PagesRoutingModule
  ]
})
export class PagesModule { }
