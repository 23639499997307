import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-testimonial',
  templateUrl: './testimonial.component.html',
  styleUrls: ['./testimonial.component.scss']
})
export class TestimonialComponent {
  constructor(private commonService: CommonService, private titleService: Title) { }

  ngOnInit(): void {
    this.titleService.setTitle('Website and Software Development Companies in India | Opus Infotech');
    const keywords = 'enterprise software companies in india, customer software development, testing companies in pune, tech due diligence, united states it companies, custom application development company, enterprise software vendors, top Inventory software companies, top Inventory software company,trusted tech team, trusted tech team inc, us it companies, us it services companies, us it company, app development companies in pune,  Umarkhed it companies Yavatmal, Yavatmal software company list, usa it companies, Pune it companies';
    const description = 'Opus Infotech is a forward-thinking software company dedicated to revolutionizing industries through innovative technology solutions. Established in 2012, our team of passionate developers, designers, and entrepreneurs is committed to creating cutting-edge software products that drive efficiency, enhance productivity, and unlock new possibilities for businesses worldwide.';
    this.commonService.updateMetaTags(keywords, description);
  }
}
